import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchActiveAgent = createAsyncThunk(
  "agents/fetch",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent; // Assuming you have activeAgent in your state

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id, // Adjust the header name as per your backend API requirements
        // Add other headers as needed
      },
    };

    const response = await axios.get(
      `https://${apiBaseUrl}/agents/${_id}`,
      config
    );

    console.log("Fetching active agent by id");
    return response.data;
  }
);

export { fetchActiveAgent };
