import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { addQuote } from "../../store/thunks/quotesThunk";
import { fetchCruiseDetails } from "../../store/thunks/widgety/fetchCruiseDetails";
import { fetchCruisePricingWithoutCruiseId } from "../../store/thunks/fetchCruisePricingWithoutCruiseId";
import { fetchAgentsClientsList } from "../../store/thunks/agentsThunk";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";

import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import shipList from "../../components/cruiseList/shipList";
import roomList from "../../components/cruiseList/roomList";

import { Listbox, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";

export default function AddQuote() {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [agentsClients, setAgentsClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isFormComplete, setIsFormComplete] = useState(true);
  const [showIncompleteForm, setShowIncompleteForm] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const [cruiseOptions, setCruiseOptions] = useState([]); // State to hold fetched cruise options

  const [roomPrices, setRoomPrices] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [nonEmptyRows, setNonEmptyRows] = useState([]);

  const [fetchedPricingData, setFetchedPricingData] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const [rows, setRows] = useState([
    {
      categoryName: "",
      categoryCode: "",
      price: "",
    },
  ]);

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        categoryName: "",
        categoryCode: "",
        price: "",
      },
    ]);
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  // REDUX USEEFFECT TO GET CLIENTS - for the client selector
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          fetchAgentsClientsList(activeAgent.email)
        );

        // You can access the response data here directly
        const agentsClientsData = response.payload;
        setAgentsClients(agentsClientsData); // Set the clients in your component state
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();

    dispatch(fetchActiveAgent());
  }, [dispatch]);

  const initialFormData = {
    CruiseLine: "",
    Ship: "",
    SailDate: "",
    Product: "",
    name: "",
    Perks: "",
    ClientFirstName: "",
    ClientLastName: "",
    ClientEmail: "",
    ClientId: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Input changed. Name:", name, "Value:", value); // Log the input change

    if (name === "SailDate") {
      // Append the desired time component to the selected date
      const dateWithTime = `${value}T04:00:00.000Z`;
      console.log("Modified SailDate:", dateWithTime); // Log the modified SailDate
      setFormData({
        ...formData,
        [name]: dateWithTime,
      });
    } else if (
      name === "Nights" ||
      name === "RoomsAvailable" ||
      name === "GroupRate" ||
      name === "MarketRate" ||
      name === "CurrentPriceAdv" ||
      name === "CurrentPercentAdv"
    ) {
      // Check if the value is empty or not a number and update state accordingly
      const numericValue =
        value === ""
          ? ""
          : !isNaN(parseFloat(value))
          ? parseFloat(value)
          : formData[name];
      console.log("Numeric value for", name, ":", numericValue); // Log the numeric value
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      console.log("Setting value for", name, ":", value); // Log setting value for other fields
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleClientChange = (client) => {
    setSelectedClient(client);
    console.log("Selected Client:", client);

    // Extract values from the selected client
    const { first_name, last_name, email, _id } = client;

    // Update formData with the extracted values
    setFormData((prevFormData) => ({
      ...prevFormData,
      ClientFirstName: first_name,
      ClientLastName: last_name,
      ClientEmail: email,
      ClientId: _id,
    }));

    console.log("updated formData", formData);
  };

  const handleFetchPricingData = () => {
    const { CruiseLine, Ship, SailDate } = formData; // Destructure formData object
    let formattedSailDate;

    if (SailDate instanceof Date) {
      formattedSailDate = SailDate.toISOString().split("T")[0]; // Format SailDate to string
    } else if (typeof SailDate === "string") {
      formattedSailDate = SailDate.split("T")[0]; // If SailDate is already a string, just split it
    } else {
      // Handle other cases where SailDate is not a Date or string
      console.error("Invalid SailDate:", SailDate);
      return;
    }

    console.log("Dispatching with this data:", {
      CruiseLine,
      Ship,
      TextSailDate: formattedSailDate,
    }); // Log data before dispatching
    setIsFetching(true);
    dispatch(
      fetchCruisePricingWithoutCruiseId({
        CruiseLine,
        Ship,
        TextSailDate: formattedSailDate,
      })
    )
      .unwrap()
      .then((response) => {
        setIsFetching(false);
        setFetchedPricingData(response);
        setError(null);
      })
      .catch((error) => {
        setIsFetching(false);
        setFetchedPricingData([]);
        setError(error.message);
      });
  };

  console.log("Pricing data from backend", fetchedPricingData);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // This useEffect will now also watch for changes to formData.Ship and formData.SailDate to fetch cruise details
  useEffect(() => {
    if (formData.Ship && formData.SailDate && isSubscribed) {
      // Format SailDate as needed for your API call
      const formattedSailDate = formData.SailDate.split("T")[0];

      // Dummy endDate calculation (adjust as needed)
      const endDate = new Date(formattedSailDate);
      endDate.setDate(endDate.getDate() + 1); // Just a placeholder for whatever logic you need
      const formattedEndDate = endDate.toISOString().split("T")[0];

      dispatch(
        fetchCruiseDetails({
          ship_name: formData.Ship,
          start_date_range_beginning: formattedSailDate,
          start_date_range_end: formattedEndDate,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.cruises && response.cruises.length > 0) {
            // Update cruiseOptions with the first cruise's name
            setCruiseOptions([{ name: response.cruises[0].name }]);

            // Automatically set the Nights field based on the first cruise's cruise_nights value
            setFormData((prevFormData) => ({
              ...prevFormData,
              Nights: response.cruises[0].cruise_nights,
            }));
          }
        })
        .catch((error) => {
          console.error("Error fetching cruise details:", error);
          setCruiseOptions([]); // Reset or handle as needed
        });
    }
  }, [dispatch, formData.Ship, formData.SailDate, isSubscribed]); // Add dependencies as needed

  const handleRoomPriceChange = (index, field, value) => {
    const updatedRows = [...rows];
    const parsedValue =
      field === "price" || field === "qty" ? parseInt(value, 10) : value;
    updatedRows[index][field] = parsedValue;
    setRows(updatedRows);
    console.log("Rows:", rows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newIncompleteFields = []; // Array to store incomplete fields

    // Check if any field is empty
    if (formData.CruiseLine === "") newIncompleteFields.push("Cruise Line");
    if (formData.Ship === "") newIncompleteFields.push("Ship");
    if (formData.SailDate === "") newIncompleteFields.push("Sail Date");
    if (formData.name === "") newIncompleteFields.push("Cruise Name");
    if (formData.ClientFirstName === "")
      newIncompleteFields.push("Client First Name");
    if (formData.ClientLastName === "")
      newIncompleteFields.push("Client Last Name");
    if (formData.ClientEmail === "") newIncompleteFields.push("Client Email");

    if (newIncompleteFields.length > 0) {
      setIsFormComplete(false);
      setShowIncompleteForm(true);
      setIncompleteFields(newIncompleteFields); // Update incompleteFields state
      setTimeout(() => setShowIncompleteForm(false), 3000);
      return;
    }

    // For room prices - remove rows with any empty values
    const filteredRows = rows.filter((row) => {
      // Check if any value in the row is empty or ''
      return Object.values(row).every(
        (value) => value !== "" && value !== undefined
      );
    });

    setRows(filteredRows);

    // Construct the document to be submitted
    const quoteDocument = {
      clientInformation: {
        ClientFirstName: formData.ClientFirstName,
        ClientLastName: formData.ClientLastName,
        ClientEmail: formData.ClientEmail,
        ClientId: formData.ClientId,
      },
      cruiseInformation: {
        CruiseLine: formData.CruiseLine,
        Ship: formData.Ship,
        SailDate: formData.SailDate,
        name: formData.name,
      },
      quoteInformation: {
        roomPrices: filteredRows,
      },
      additionalInformation: {
        Notes: formData.AdditionalNotes,
      },
      agentInformation: {
        AgentEmail: activeAgent.email,
        AgentName: `${activeAgent.first_name} ${activeAgent.last_name}`,
        AgentId: activeAgent._id,
        AgentCompanyName: activeAgent.company_name,
        AgentCompanyNumber: activeAgent.company_number,
        AgentSubdomain: activeAgent.company_subdomain,
      },
    };

    // Convert SailDate to a Date object before submitting
    const sailDate = new Date(formData.SailDate);

    // Update formData with the converted SailDate
    const formDataWithDate = {
      ...formData,
      SailDate: sailDate,
    };

    console.log("form data with modified date", formDataWithDate);
    console.log("modified saildate from handlesubmit", sailDate);

    // dispatch(addQuote(formDataWithDate))
    dispatch(addQuote(quoteDocument))
      .unwrap()
      .then((response) => {
        console.log("Quote added successfully. Response:", response);
        setFormData(initialFormData);
        setFeedback(<SuccessFeedback onClose={() => setFeedback(null)} />);
        setTimeout(() => setFeedback(null), 3000);
        setIsFormComplete(true); // Set form complete state to true after successful submission
      })
      .catch((error) => {
        console.error("Failed to add cruise:", error);
        setFeedback(
          <ErrorFeedback
            onClose={() => setFeedback(null)}
            incompleteFields={newIncompleteFields}
          />
        );
        setTimeout(() => setFeedback(null), 3000);
      });
  };

  // Reset the showIncompleteForm state after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowIncompleteForm(false), 4000);
    return () => clearTimeout(timer);
  }, [showIncompleteForm]);

  const handleClear = () => {
    setFormData(initialFormData);
    setFetchedPricingData([]);
    setRoomPrices([]);
    setSelectedClient(null); // Reset selectedClient state
    setRows([]);
  };

  // Derive unique cruise lines
  const uniqueCruiseLines = [
    ...new Set(shipList.map((ship) => ship.cruiseLine)),
  ];

  // Function to get category name from categoryCode
  function getCategoryName(categoryCode, cruiseLine) {
    const room = roomList.find((room) => room.categoryCode === categoryCode);
    if (!room) return categoryCode;

    return room.categoryName;
  }

  function SuccessFeedback({ onClose }) {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              Successfully uploaded
            </p>
            <div className="mt-2 text-sm text-green-700">
              <p>Your client will now be able to see your quote.</p>
            </div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ErrorFeedback({ onClose, incompleteFields }) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              There was an error with your submission
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Please check your inputs and try again.</p>
              {incompleteFields.length > 0 && (
                <div>
                  <p>Fields with missing data:</p>
                  <ul>
                    {incompleteFields.map((field, index) => (
                      <li key={index}>{field}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function SuccessNotification({ nonEmptyRows }) {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">
              Prices saved to quote
            </h3>
            {nonEmptyRows.length > 0 ? (
              <div className="mt-2 text-sm text-gray-600">
                <ul>
                  {nonEmptyRows.map((row, index) => (
                    <li key={index}>
                      {row.categoryName}, {row.categoryCode}, ${row.price}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="mt-2 text-sm text-gray-600">No data entered.</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  const isRowValid = (row) => {
    return (
      typeof row.categoryName === "string" &&
      typeof row.categoryCode === "string" &&
      (typeof row.price === "string" || typeof row.price === "number") &&
      (typeof row.price === "string" ? row.price.trim() !== "" : true)
    );
  };

  return (
    <div className="pt-4 pb-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="space-y-6">
          {/* Top text */}
          <div className="border-b border-gray-900/10 pb-8">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Add Quote
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              Use this form to create a quote for your clients.
            </p>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              All fields with * are necessary. This quote can be edited after
              submission.
            </p>
          </div>

          {/* Subscription warning */}
          {isSubscribed ? null : <NoSubscriptionWarning />}

          <div className="border-b border-gray-900/10 pb-12 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-6">
            {/* Client Information bit of form */}
            <div className="sm:col-span-3">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Client Information
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-600">
                Select from your existing clients.
              </p>
              <div
                className={`relative ${
                  !isSubscribed && "pointer-events-none"
                }`}>
                {!isSubscribed && (
                  <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
                )}

                {/* New Client Information bit of form */}
                <Listbox value={selectedClient} onChange={handleClientChange}>
                  {({ open }) => (
                    <>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="inline-flex w-full truncate">
                            <span className="truncate">
                              {selectedClient
                                ? `${selectedClient.first_name} ${selectedClient.last_name}`
                                : "Select a client"}
                            </span>
                            <span className="ml-2 truncate text-gray-500">
                              {selectedClient ? selectedClient.email : ""}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {agentsClients.map((client) => (
                              <Listbox.Option
                                key={client._id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={client}>
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "truncate"
                                        )}>
                                        {client.first_name} {client.last_name}
                                      </span>
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-indigo-200"
                                            : "text-gray-500",
                                          "ml-2 truncate"
                                        )}>
                                        {client.email}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}>
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>

            {/* First Name Input */}
            <div>
              <label
                htmlFor="clientFirstName"
                className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                id="clientFirstName"
                name="clientFirstName"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={formData.ClientFirstName}
                onChange={(e) =>
                  setFormData({ ...formData, ClientFirstName: e.target.value })
                }
              />
            </div>

            {/* Last Name Input */}
            <div>
              <label
                htmlFor="clientLastName"
                className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="clientLastName"
                name="clientLastName"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={formData.ClientLastName}
                onChange={(e) =>
                  setFormData({ ...formData, ClientLastName: e.target.value })
                }
              />
            </div>

            {/* Email Input */}
            <div>
              <label
                htmlFor="clientEmail"
                className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="clientEmail"
                name="clientEmail"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={formData.ClientEmail}
                onChange={(e) =>
                  setFormData({ ...formData, ClientEmail: e.target.value })
                }
              />
            </div>
          </div>

          {/* Cruise Information bit of form */}
          <>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Cruise Information
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-600">
                Select the Cruise Line, Cruise Ship, and Sail Date to search for
                the product.
              </p>
              <div
                className={`relative ${
                  !isSubscribed && "pointer-events-none"
                }`}>
                {!isSubscribed && (
                  <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
                )}
                <div className="mt-10 grid grid-cols-1 sm:grid-cols-9 gap-x-6 gap-y-8">
                  <>
                    {/* Cruise Line */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="cruise-line"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Cruise Line *
                      </label>
                      <div className="mt-2">
                        <select
                          name="CruiseLine"
                          id="cruise-line"
                          value={formData.CruiseLine}
                          onChange={(e) => {
                            const selectedCruiseLine = e.target.value;
                            setFormData({
                              ...formData,
                              CruiseLine: selectedCruiseLine,
                              Ship: "", // Reset Ship when Cruise Line changes
                            });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="">Select cruise line</option>
                          {uniqueCruiseLines.map((cruiseLine) => (
                            <option key={cruiseLine} value={cruiseLine}>
                              {cruiseLine}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Cruise Ship */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="ship"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Cruise Ship *
                      </label>
                      <div className="mt-2">
                        <select
                          name="Ship"
                          id="ship"
                          value={formData.Ship}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="">Select ship</option>
                          {formData.CruiseLine &&
                            shipList
                              .filter(
                                (ship) =>
                                  ship.cruiseLine === formData.CruiseLine
                              )
                              .map((ship) => (
                                <option key={ship.ship} value={ship.ship}>
                                  {ship.ship}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>

                    {/* Sail Date */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="sail-date"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Sail Date *
                      </label>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="SailDate"
                          id="sail-date"
                          value={
                            formData.SailDate
                              ? formData.SailDate.split("T")[0]
                              : ""
                          } // Extracting only the date part
                          onChange={handleInputChange}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    {/* Cruise Name - from Widgety */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Cruise Name
                      </label>
                      <div className="mt-2">
                        {cruiseOptions.length > 0 ? ( // Check if cruiseOptions is not empty
                          <select
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option value="">Select Cruise Name</option>
                            {cruiseOptions.map((option, index) => (
                              <option key={index} value={option.ref}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          // Render input field if cruiseOptions is empty
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter Cruise Name"
                          />
                        )}
                      </div>
                    </div>

                    {/* Button Wrapper */}
                    <div className="sm:col-span-3 flex justify-end"></div>
                  </>
                </div>
              </div>
            </div>
          </>

          {/* Pricing Data */}
          <>
            <div className="border-b border-gray-900/10 pb-12">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Pricing Data
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Fetch this data for a quick reference to create a pricing
                    package for your client.
                  </p>
                  <p className="mt-2 text-sm text-gray-700">
                    Your client <strong>will not</strong> see this information
                    on their quote.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-4">
                  <button
                    type="button"
                    onClick={handleFetchPricingData}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Fetch Prices
                  </button>
                </div>
              </div>

              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                            Category
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Group Rate
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Rooms Available
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {fetchedPricingData.length === 0 ? (
                          <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                              Waiting to fetch
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Waiting to fetch
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Waiting to fetch
                            </td>
                          </tr>
                        ) : (
                          fetchedPricingData.map((data) => (
                            <tr key={data._id} className="even:bg-gray-50">
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {data.Category} -{" "}
                                {getCategoryName(
                                  data.Category,
                                  data.CruiseLine
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {`${data.GroupRate.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 2,
                                })} ${data.Currency}`}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {data.RoomsAvailable}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>

          {/* New Inline Pricing Table */}
          <div>
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-7 text-gray-900">
                  Room Price Information
                </h1>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  Your client <strong>will</strong> see this information on
                  their quote, in a table.
                </p>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  Empty rows will be discarded.
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  onClick={addNewRow}
                  type="button"
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Add Row
                </button>
              </div>
            </div>

            {showSuccessMessage && (
              <SuccessNotification nonEmptyRows={nonEmptyRows} />
            )}

            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Category Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Category Code
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Price (per room)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="pr-3 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              placeholder="Eg: Concierge Class"
                              className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                              value={row.categoryName}
                              onChange={(e) =>
                                handleRoomPriceChange(
                                  index,
                                  "categoryName",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="pr-3 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              placeholder="Eg: C1"
                              className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                              value={row.categoryCode}
                              onChange={(e) =>
                                handleRoomPriceChange(
                                  index,
                                  "categoryCode",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="pr-3 py-4 whitespace-nowrap">
                            <input
                              type="number"
                              min="0"
                              step="1"
                              placeholder="Eg: 2"
                              className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                              value={row.qty}
                              onChange={(e) =>
                                handleRoomPriceChange(
                                  index,
                                  "qty",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="pr-3 py-4 whitespace-nowrap">
                            <input
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Eg: 1800.00"
                              className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                              value={row.price}
                              onChange={(e) =>
                                handleRoomPriceChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="pr-3 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              placeholder="Notes"
                              className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                              value={row.notes}
                              onChange={(e) =>
                                handleRoomPriceChange(
                                  index,
                                  "notes",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Quote Information bit of form */}
          <>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Quote Information
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-600">
                Input extra information associated with this quote. We suggest
                currency, deadlines, disclaimers, etc.
              </p>
              <p className="mt-2 text-sm leading-6 text-gray-600">
                Leave empty if no notes required.
              </p>
              <div
                className={`relative ${
                  !isSubscribed && "pointer-events-none"
                }`}>
                {!isSubscribed && (
                  <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
                )}
                <div className="mt-10 grid grid-cols-1 sm:grid-cols-9 gap-x-6 gap-y-8">
                  <>
                    {/* Additional notes */}
                    <div className="col-span-full">
                      <label
                        htmlFor="perks"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        <span className="flex items-center">Quote Notes</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="AdditionalNotes"
                          id="notes"
                          placeholder="Eg: All currencies in CAD. $900.00 deposit due within 7 days of booking. Prices include all taxes and fees for 2 adults."
                          value={formData.AdditionalNotes}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>

          {feedback && feedback}

          {/* Render incomplete form if the form is not considered complete and the timer is active */}
          {!isFormComplete && showIncompleteForm && (
            <div className="rounded-md bg-yellow-50 p-4 mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Unable to add - All fields must be complete
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    {/* Display incomplete fields feedback */}
                    {incompleteFields.length > 0 && (
                      <div>
                        <p>Fields with missing data:</p>
                        <ul>
                          {incompleteFields.map((field, index) => (
                            <li key={index}>{field}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Clear / Add Quote */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={handleClear}
              className="text-sm font-semibold leading-6 text-gray-900">
              Clear
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Add Quote
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
